import React from 'react'
const aboutInfo = [
    {
      img: "./assets/img/package.png",
      alt: "package",
      h3:"Виробництво",
      p:"Сучасне обладнання, автоматизовані лінії, інженерні інновації та спектр доступних матеріалів дозволяють виготовляти вікна та двері для реалізації всіх архітектурних ідей. Виробничі потужності компанії розташовані у місті Хмельницькому. Складські та виробничі приміщення складають 1800 м². Крім цього підприємство має великий склад фурнітури та комплектуючих, додаткових виробів (підвіконь, відливів, москітних сіток, жалюзі). На усіх етапах виробництва діє система контролю якості, щоб продукція відповідала нормативним документам і міжнародним стандартам. Автоматизовані виробничі потужності дозволяють виготовляти замовлення клієнтів у найкоротші строки."
    },
    {
        img: "./assets/img/shopping-cart.svg",
        alt: "shoping",
        h3:"Торгівля",
        p:"Інше напрямок діяльності підприємства - це гуртова та роздрібна торгівля міжкімнатними та вхідними дверима. Наш магазин працює з 2002 року. На торговій площі в 300 м² представлено понад 180 зразків вхідних і міжкімнатних дверей, секційних та захисних ролет 20 торгових марок. Ми пропонуємо сертифіковану продукцію вітчизняних виробників. Широкий стилістичний асортимент різних брендів з якісною фурнітурою стануть окрасою ваших осель. Кожний господар прагне обрати для себе найкраще. Надійність! Якість! Дизайн! Усе це можна побачити у нашому магазині по вулиці В'ячеслава Чорновола, 31/1 у Хмельницькому."
    },
    {
        img: "./assets/img/users.svg",
        alt: "users",
        h3:"Спеціалісти",
        p:"Штат співробітників виробництва налічує понад 40 фахівців. Це інженери, працівники відділу контролю, монтажники. Усі вони мають великий досвід роботи на ринку металопластикових виробів і проходять спеціалізоване навчання відповідного профілю. Наші менеджери завжди готові відповісти на будь-яке запитання клієнта, грамотно його проконсультують щодо оптимального вибору (з урахуванням вимог і фінансових можливостей, конкретних умов експлуатації."
    },
    {
        img: "./assets/img/home.svg",
        alt: "home",
        h3:"Об’єкти",
        p:"Фасади, вітрини, зимові сади, перегородки, двері, вікна, розсувні конструкції – це неповний перелік продукції, що виготовляється компанією. Серед наших об’єктів не тільки квартири та приватні будинки, а і новобуди забудовників ПФ «Діта», ПМП «Лідер», ЖК «Гармонія», ЖК «Авіла», а також комунальні заклади. Одні з останніх завершених проєктів – Хмельницький обласний тубдиспансер, обласний онкологічний диспансер, термінал Нової пошти, амбулаторії сімейної медицини. Ми працюємо з навчальними закладами у Хмельницькому, зокрема з НВО №1, ліцеєм №17, новим дитячий садком у мікрорайоні Лезневе, а також з навчальними закладами у Волочиському, Ярмолинецькому, Красиліському районах."
    }
  ];

export default function AboutItem(){
    return(
        <>
            {
                aboutInfo.map((item,index)=>
                <div className="about__block" key={index}>
                    <div className="about__img">
                        <img src={item.img} alt={item.alt} />
                    </div>
                    <div className="about__info">
                        <h3>{item.h3}</h3>
                        <p>{item.p}</p>
                    </div>
                </div>
                )
            }
        </>
    )
}