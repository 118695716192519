import React from "react";
import "./Goal.scss";
const goal = [
  {
    img: "./assets/img/rolet.jpg",
    title: "Ролетні ворота",
    description:
      "Ролетні ворота складаються з алюмінієвих профілів, які згортаються в захисний короб, розташований у верхній частині воріт. Пропонуємо профілі Alutech роликової прокатки з м'яким і твердим пінним наповнювачем, а також екструдовані профілі — вентиляційні та оглядові. Основний матеріал для виготовлення ролетних воріт і гаражних рольставень — алюміній — забезпечує високу стійкість конструкції до корозії, впливу атмосферних опадів, перепадів температур, гарантує міцність, надійність і екологічність. Висота накривної поверхні всіх профілів для рольворіт — 77 мм, товщина — 19 мм. Рольворота ідеально підходять для об'єктів, де під'їзд до гаража займає невелику територію або в'їзд відбувається із тротуару. ",
    partners: [
      { img: "./assets/img/partner/Rodos.svg" },
      { img: "./assets/img/partner/Rodos.svg" },
      { img: "./assets/img/partner/Rodos.svg" },
    ],
  },
  {
    img: "./assets/img/prom.jpg",
    title: "Промислові ворота",
    description:
      "Ворота використовуються на промислових і сільськогосподарських об'єктах, в автосалонах, на автомийках і СТО, в пожежних частинах і на складах, у спорудах громадського призначення. Alutech пропонує два види промислових воріт - ProTrend і ProPlus. Вироби, представлені в них, відрізняються технічними властивостями, а також доступними габаритними розмірами. Товщина панелей 40 і 45 мм, гарантований термін служби пружин - 100 000 циклів. Також до Базової Комплектації входить система безпеки.",
    partners: [{ img: "./assets/img/partner/Rodos.svg" }],
  },
  {
    img: "./assets/img/panoram.jpg",
    title: "Панорамні ворота",
    description:
      "Витончена алюмінієва конструкція з максимальним склінням відповідає сучасним вимогам до дизайнерського оформлення, забезпечуючи при цьомухорошу тепло- і звукоізоляцію. Панорамні ворота є ідеальним рішенням для будинків у стилі Hi-tech і автосалонів. Панорамні ворота Alutech представлені трьома серіями, які враховують будь-які запити клієнтів: AluTrend, AluPro і AluTherm.",
    partners: [{ img: "./assets/img/partner/Rodos.svg" }],
  },
  {
    img: "./assets/img/4.webp",
    title: "В’їзні ворота",
    description:
      "Система комплектуючих Alutech застосовується для виготовлення в'їзних конструкцій: відкатних, розпашних воріт і хвірток, а також секцій огорож. Система призначена для встановлення на приватних і промислових об'єктах. Особлива конструкція профілів рами дозволяє створити десятки рішень щодо заповнення воріт. Каркас обшивається сендвіч-панелями, ролетним або екструдованим профілем. Забезпечується стійкість до зовнішніхчинників: оцинковка, полімеризація і поліамідні частки зовнішнього покриття надійно захищають від впливу навколишнього середовища. Жорсткість полотна забезпечує наповнювач щільністю до 47 кг/м3. Між панелями знаходиться ущільнювач, завдяки чому полотно виглядає монолітно, витримує сильний вітер і не провисає. Мінімальний робочий ресурс опор становить 15 000-20 000 циклів відкриття-закриття, що відповідає приблизно 10-15 роками експлуатації воріт.",
    partners: [{ img: "./assets/img/partner/Rodos.svg" }],
  },
];
export default function Goal() {
  return (
    <>
      {goal.map((item, index) => (
        <div className="goal__flex" key={index}>
          <div className="goal__img">
            <img src={item.img} alt="" />
          </div>
          <div className="goal__info">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <div className="goal__comany">
              {item.partners.map((img, index) => (
                <img src={img.img} alt="" key={index} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
