import React from "react";
import { Component } from "react";
import axios from "axios";

const API_PATH = "https://develooper.website/phone.php";

class HeroForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: `${API_PATH}`,
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
          phone:"",
        });
        console.log(result.data);
      })
      .catch((error) => this.setState({ error: error.message }));
  };
  render() {
    return (
      <div className="hero__form">
        <form action="" method="post">
          <input
            type="tel"
            name="user_phone"
            className="hero__input"
            id="user_phone"
            placeholder="Номер телефону"
            onChange={(e) => this.setState({ phone: e.target.value })}
            value={this.state.phone}
          />
          <input type="submit" className="hero__button" onClick={(e) => this.handleFormSubmit(e)} value=""/>
        </form>
        <p className="hero__form-description">
          Залишите свої контактні дані і менеджер зв'яжеться з вами
        </p>
      </div>
    );
  }
}

export default HeroForm;
