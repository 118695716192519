import React from 'react'
import MainForm from '../Components/MainForm'

export default function Service(){
    return (
        <React.Fragment>
            <section className="service">
                <div className="container">
                    <h2 className="title">Послуги</h2>
                    <div className="service__flex">
                        <div className="service__item">
                            <img src="./assets/img/scale.svg" alt=""/>
                            <div className="service__info">
                                <h3>Заміри</h3>
                                <p>Безкоштовний виїзд в зручний для Вас час на протязі 1 дня</p>
                            </div>
                        </div>
                        <div className="service__item">
                            <img src="./assets/img/keys.svg" alt=""/>
                            <div className="service__info">
                                <h3>Прорахунок</h3>
                                <p>Швидкий автоматизований прорахунок, вичерпна консультація.</p>
                            </div>
                        </div>
                        <div className="service__item">
                            <img src="./assets/img/truck.svg" alt=""/>
                            <div className="service__info">
                                <h3>Доставка</h3>
                                <p> Безкоштовна доставка в межах міста власним спеціалізованим транспортом</p>
                            </div>
                        </div>
                        <div className="service__item">
                            <img src="./assets/img/tool.svg" alt=""/>
                            <div className="service__info">
                                <h3>Монтаж</h3>
                                <p>Професійний монтаж віконних та дверних систем сертифікованими спеціалістами</p>
                            </div>
                        </div>
                        <div className="service__item">
                            <img src="./assets/img/awardService.svg" alt=""/>
                            <div className="service__info">
                                <h3>Гарантійне обслуговування</h3>
                                <p> Гарантія на продукцію. Щорічне безкоштовне (протягом 5 років)  регулювання фурнітури</p>
                            </div>
                        </div>
                        <div className="service__item">
                            <img src="./assets/img/fileText.svg" alt=""/>
                            <div className="service__info">
                                <h3>Проектна документація</h3>
                                <p>Пакет грамотно оформлених документів  для бюджетних організацій та приватних осіб</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <MainForm/>
        </React.Fragment>
    )
}